
import { useMeta } from "vue-meta";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          title: "Our rooms",
          subTitle: `L'agriturismo la casa in campagna has 3 bedrooms, 
          gathered in a single structure, a properly renovated country 
          cottage. Immersed in the countryside and away from traffic, 
          the farmhouse allows you to enjoy relaxing silence, in large 
          rooms with a traditional flavor. La Casa in Campagna offers a 
          barbecue, terrace, restaurant and free private parking on site. 
          The rooms have a private bathroom with free
           toiletries and a hairdryer. The breakfast, served in the hall 
           on the ground floor, will make you start the day in the right way, with homemade cakes and savory choices prepared at the moment.`,
          facilitiesTitle: "Facilities",
          ac: "Air conditioning",
          parking: "Free private parking",
          bathroom: "Private bathroom",
          breakfast: "Breakfast included",
          roomView: "Garden view",
          wifi: "Free wifi",
          bookNow: "Book now",
          galleryTitle: "Gallery",
        },
        it: {
          title: "Le nostre camere",
          subTitle: `L’ Agriturismo La casa in campagna dispone di 3 camere da letto,
            raccolti in un unica struttura, un casolare di campagna
            ristrutturato a dovere. Immerso nella campagna e lontano dal
            traffico, l’agriturismo vi permette di godere silenzio relax, in
            camere ampie e dal sapore tradizionale. La Casa in Campagna offre un
            barbecue, una terrazza, un ristorante e un parcheggio privato
            gratuito in loco. Le camere dispongono di bagno privato con set di
            cortesia e asciugacapelli. La colazione, servita nella sala al piano
            terra, vi farà iniziare la giornata nel modo giusto, con le torte
            fatte in casa e le scelte salate preparate al momento.`,
          facilitiesTitle: "I Servizi",
          ac: "Aria condizionata",
          parking: "Parcheggio privato gratuito",
          bathroom: "Bagno privato",
          breakfast: "Colazione inclusa",
          roomView: "Vista giardino",
          wifi: "Free wifi",
          bookNow: "Prenota ora",
          galleryTitle: "Galleria",
        },
      },
    });
    useMeta({
      title: "Camere",
      description: `L’ Agriturismo La casa in campagna dispone di 3 camere da letto,
            raccolti in un unica struttura, un casolare di campagna
            ristrutturato a dovere. Immerso nella campagna e lontano dal
            traffico, l’agriturismo vi permette di godere silenzio relax, in
            camere ampie e dal sapore tradizionale. La Casa in Campagna offre un
            barbecue, una terrazza, un ristorante e un parcheggio privato
            gratuito in loco. Le camere dispongono di bagno privato con set di
            cortesia e asciugacapelli. La colazione, servita nella sala al piano
            terra, vi farà iniziare la giornata nel modo giusto, con le torte
            fatte in casa e le scelte salate preparate al momento.`,
    });

    return {
      t,
      slide: ref(1),
      fullscreen: ref(false),
      slidedue: ref(1),
      camereSlide: ref(0),
      camere: [
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/b25a75f8-5bf8-4841-9401-a93c3e25373c_dzin85.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/26cd2eec-2a31-4697-86d5-1f60f563ce81_q8nll0.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/fa0e6b60-a1f0-4bc0-8627-07ffba9ce3cd_xebbol.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393914/casa-in-campagna/camere/IMG_20210613_153003_cz6y1v.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393910/casa-in-campagna/camere/IMG_20210613_153044_seqgib.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393911/casa-in-campagna/camere/IMG_20210613_152955_inbfv7.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393903/casa-in-campagna/camere/IMG_20210613_152945_ndbtlo.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1593946935/casa-in-campagna/camere/camera_nbhsei.jpg",
      ],
    };
  },
  data() {
    return {
      showImageGallery: false,
      index: 0,
    };
  },
  methods: {
    showImages(index: number) {
      this.index = index;
      this.showImageGallery = true;
    },
    openBooking() {
      window.open("http://www.booking.com/Share-p4Tu6J", "_blank", "noopener");
    },
  },
});
