import { render } from "./Camere.vue?vue&type=template&id=47c3cd1a"
import script from "./Camere.vue?vue&type=script&lang=ts"
export * from "./Camere.vue?vue&type=script&lang=ts"

import "./Camere.vue?vue&type=style&index=0&id=47c3cd1a&lang=css"
script.render = render

export default script
import QImg from 'quasar/src/components/img/QImg.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QList,QItem,QItemSection,QIcon,QBtn,QCarousel,QCarouselSlide,QCarouselControl});
