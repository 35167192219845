<template>
  <div class="q-pb-xl">
    <q-img
      alt="camere"
      :position="!$q.platform.is.mobile ? '0 -300px' : null"
      height="400px"
      src="https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/fa0e6b60-a1f0-4bc0-8627-07ffba9ce3cd_xebbol.jpg"
    />

    <div class="container">
      <div>
        <div class="flex justify-center">
          <h4 class="text-center" v-if="$q.platform.is.mobile">
            {{ t("title") }}
          </h4>
          <h3 v-else>{{ t("title") }}</h3>
          <p
            :class="[
              'text-subtitle1 d-none',
              $q.platform.is.mobile ? 'q-px-md' : '',
            ]"
          >
            {{ t("subTitle") }}
          </p>
        </div>
      </div>

      <!-- Le nostre camere -->
      <!-- Mobile -->
      <template v-if="$q.platform.is.mobile">
        <div class="row">
          <div class="col-12 q-pt-lg q-px-md">
            <q-img
              alt="camere"
              src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/45620485.jpg?k=b35bd2997e57969f92e58056e8d5892cd418973d44b1e54b0f6334c27cc00231&o=&hp=1"
            />
          </div>
          <div class="col-12 q-px-md bg-secondary">
            <h4>{{ t("facilitiesTitle") }}</h4>
            <q-list>
              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="home" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section>20 &#13217;</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="wifi" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section>{{ t("wifi") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="ac_unit" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("ac") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="yard" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("roomView") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="shower" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("bathroom") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="free_breakfast" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("breakfast") }}</q-item-section>
              </q-item>

              <q-btn
                @click="openBooking"
                class="q-mt-xl"
                color="primary"
                label="Prenota ora"
              />
            </q-list>
          </div>
        </div>
      </template>

      <!-- Desktop -->
      <template v-else>
        <div class="row q-pt-xl">
          <div class="col-12 col-md-6">
            <q-img
              alt="camere"
              height="700px"
              src="https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/26cd2eec-2a31-4697-86d5-1f60f563ce81_q8nll0.jpg"
            />
          </div>
          <div class="col-12 col-md-6 q-px-xl q-py-xl bg-secondary">
            <h4>{{ t("facilitiesTitle") }}</h4>
            <q-list>
              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="home" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section>20 &#13217;</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="wifi" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("wifi") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="ac_unit" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("ac") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="local_parking" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("parking") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="shower" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("bathroom") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="free_breakfast" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("breakfast") }}</q-item-section>
              </q-item>

              <q-item class="q-pl-none">
                <q-item-section avatar>
                  <q-icon name="yard" style="font-size: 2rem;" />
                </q-item-section>

                <q-item-section> {{ t("roomView") }}</q-item-section>
              </q-item>
            </q-list>

            <q-btn
              @click="openBooking"
              class="q-mt-xl"
              color="primary"
              :label="t('bookNow')"
            />
          </div>
        </div>
      </template>

      <h4 class="text-center">{{ t("galleryTitle") }}</h4>
      <q-carousel
        v-if="$q.platform.is.mobile"
        v-model="slide"
        v-model:fullscreen="fullscreen"
        transition-prev="slide-right"
        transition-next="slide-left"
        swipeable
        animated
        control-color="primary"
        navigation
        padding
        arrows
        class="bg-grey-1 shadow-2 rounded-borders"
      >
        <q-carousel-slide
          v-for="(camera, index) in camere"
          :key="index"
          :name="index + 1"
          :img-src="camera"
        />
        <template v-slot:control>
          <q-carousel-control position="bottom-right" :offset="[18, 18]">
            <q-btn
              push
              round
              dense
              color="white"
              text-color="primary"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
              @click="fullscreen = !fullscreen"
            />
          </q-carousel-control>
        </template>
      </q-carousel>

      <div v-else class="row q-col-gutter-xl">
        <div
          v-for="(camera, index) in camere"
          :key="index"
          @click="showDialog"
          class="col-12 col-md-4"
        >
          <q-img
            alt="camere"
            class="cursor-pointer"
            @click="showImages(index)"
            height="400px"
            :src="camera"
          />
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="showImageGallery"
      :imgs="camere"
      :index="index"
      @hide="showImageGallery = false"
    ></vue-easy-lightbox>
  </div>
</template>

<script lang="ts">
import { useMeta } from "vue-meta";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          title: "Our rooms",
          subTitle: `L'agriturismo la casa in campagna has 3 bedrooms, 
          gathered in a single structure, a properly renovated country 
          cottage. Immersed in the countryside and away from traffic, 
          the farmhouse allows you to enjoy relaxing silence, in large 
          rooms with a traditional flavor. La Casa in Campagna offers a 
          barbecue, terrace, restaurant and free private parking on site. 
          The rooms have a private bathroom with free
           toiletries and a hairdryer. The breakfast, served in the hall 
           on the ground floor, will make you start the day in the right way, with homemade cakes and savory choices prepared at the moment.`,
          facilitiesTitle: "Facilities",
          ac: "Air conditioning",
          parking: "Free private parking",
          bathroom: "Private bathroom",
          breakfast: "Breakfast included",
          roomView: "Garden view",
          wifi: "Free wifi",
          bookNow: "Book now",
          galleryTitle: "Gallery",
        },
        it: {
          title: "Le nostre camere",
          subTitle: `L’ Agriturismo La casa in campagna dispone di 3 camere da letto,
            raccolti in un unica struttura, un casolare di campagna
            ristrutturato a dovere. Immerso nella campagna e lontano dal
            traffico, l’agriturismo vi permette di godere silenzio relax, in
            camere ampie e dal sapore tradizionale. La Casa in Campagna offre un
            barbecue, una terrazza, un ristorante e un parcheggio privato
            gratuito in loco. Le camere dispongono di bagno privato con set di
            cortesia e asciugacapelli. La colazione, servita nella sala al piano
            terra, vi farà iniziare la giornata nel modo giusto, con le torte
            fatte in casa e le scelte salate preparate al momento.`,
          facilitiesTitle: "I Servizi",
          ac: "Aria condizionata",
          parking: "Parcheggio privato gratuito",
          bathroom: "Bagno privato",
          breakfast: "Colazione inclusa",
          roomView: "Vista giardino",
          wifi: "Free wifi",
          bookNow: "Prenota ora",
          galleryTitle: "Galleria",
        },
      },
    });
    useMeta({
      title: "Camere",
      description: `L’ Agriturismo La casa in campagna dispone di 3 camere da letto,
            raccolti in un unica struttura, un casolare di campagna
            ristrutturato a dovere. Immerso nella campagna e lontano dal
            traffico, l’agriturismo vi permette di godere silenzio relax, in
            camere ampie e dal sapore tradizionale. La Casa in Campagna offre un
            barbecue, una terrazza, un ristorante e un parcheggio privato
            gratuito in loco. Le camere dispongono di bagno privato con set di
            cortesia e asciugacapelli. La colazione, servita nella sala al piano
            terra, vi farà iniziare la giornata nel modo giusto, con le torte
            fatte in casa e le scelte salate preparate al momento.`,
    });

    return {
      t,
      slide: ref(1),
      fullscreen: ref(false),
      slidedue: ref(1),
      camereSlide: ref(0),
      camere: [
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/b25a75f8-5bf8-4841-9401-a93c3e25373c_dzin85.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/26cd2eec-2a31-4697-86d5-1f60f563ce81_q8nll0.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1633378462/casa-in-campagna/camere/fa0e6b60-a1f0-4bc0-8627-07ffba9ce3cd_xebbol.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393914/casa-in-campagna/camere/IMG_20210613_153003_cz6y1v.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393910/casa-in-campagna/camere/IMG_20210613_153044_seqgib.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393911/casa-in-campagna/camere/IMG_20210613_152955_inbfv7.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393903/casa-in-campagna/camere/IMG_20210613_152945_ndbtlo.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1593946935/casa-in-campagna/camere/camera_nbhsei.jpg",
      ],
    };
  },
  data() {
    return {
      showImageGallery: false,
      index: 0,
    };
  },
  methods: {
    showImages(index: number) {
      this.index = index;
      this.showImageGallery = true;
    },
    openBooking() {
      window.open("http://www.booking.com/Share-p4Tu6J", "_blank", "noopener");
    },
  },
});
</script>

<style>
.vel-toolbar {
  display: none !important;
}
</style>
